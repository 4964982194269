import React from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import List from 'src/components/shared/List/List';
import { FadeIn } from 'src/components/shared/Animations/Animations';
import {useI18next} from "gatsby-plugin-react-i18next";
import useLng from "../../../../hooks/useLng";

const StyledParagraph = styled(Paragraph)`
  margin-bottom: ${({ smallMb }) => (smallMb ? '6rem' : '11rem')};

  @media screen and (max-width: 767px) {
    margin-bottom: 4rem;
  }
`;

const data = [
  'hurtownią,',
  'zewnętrznymi serwisami (Allegro, Ceneo i inne),',
  'szybkimi płatnościami (PayU, TPay, PayPal, Przelewy24, DotPay itd.),',
  'usługami kurierskimi (InPost, DHL, DPD, UPS, Furgonetka, Poczta Polska),',
  'usługami wspierającymi marketing (Facebook, Instagram, Google Shopping),',
  'narzędziami Google do SEO i analityki (Google Search Console, Google Analytics, Google Tag Manager),',
  'dodatkowymi rozszerzeniami (live chaty, waitlist, porównywarki produktów, itd.).',
];

const dataEN = [
  'wholesale,',
  'external platforms (Allegro, Ceneo, and others),',
  'fast payments (PayU, TPay, PayPal, Przelewy24, DotPay, etc.),',
  'courier services (InPost, DHL, DPD, UPS, Furgonetka, Polish Post),',
  'marketing support services (Facebook, Instagram, Google Shopping),',
  'Google tools for SEO and analytics (Google Search Console, Google Analytics, Google Tag Manager),',
  'additional extensions (live chats, waitlists, product comparators, etc.).',
];

const OnlineShopIntegrationSection = () => {
  const {language} = useI18next();
  const {t} = useLng();
  return (
      <Section>
        <Wrapper nonGrid>
          <FadeIn>
            <Label>{t("shop19")}</Label>
          </FadeIn>
          <FadeIn>
            <StyledParagraph>
              {t("shop20")}
            </StyledParagraph>
          </FadeIn>
          <StyledParagraph smallMb>
            {t("shop21")}
          </StyledParagraph>
          <List items={language === "pl" ? data : dataEN}/>
        </Wrapper>
      </Section>
  );
}

export default OnlineShopIntegrationSection;
