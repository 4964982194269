import React, { createContext } from 'react';
import styled from 'styled-components';
import SEO from 'src/components/seo';
import Layout  from 'src/layouts/Layout';
import SocialMedia from 'src/components/SocialMedia/SocialMedia';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import {
  Section,
  MainSection,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import {
  HeaderParagraph,
  HeaderSection,
  HeaderTextWrapper,
  HeaderTitle,
} from 'src/components/Sites/ServicesPages/shared/Header';
import WhatYouGetSection from 'src/components/Sites/ServicesPages/OnlineShops/WhatYouGetSection';
import PopularPlatformsSection from 'src/components/Sites/ServicesPages/OnlineShops/PopularPlatformsSection';
import OnlineShopIntegrationSection from 'src/components/Sites/ServicesPages/OnlineShops/OnlineShopIntegrationSection';
import UIUXSection from 'src/components/Sites/ServicesPages/OnlineShops/UIUXSection';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import { graphql } from 'gatsby';
import { FadeIn } from 'src/components/shared/Animations/Animations';
import FadeLink from 'src/components/shared/navigations/FadeLink/FadeLink';
import useLng from "src/hooks/useLng"

export const OnlineShopsPageContext = createContext({});

const StyledImageWrapper = styled(ImageWrapper)`
  max-width: 50vw;
  width: 100%;
  flex-shrink: 0;

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const StyledSocialMedia = styled(SocialMedia)`
  position: absolute;
  left: 5rem;
  top: 7rem;
  padding: 4rem 7rem 0 0;

  @media screen and (max-width: 1199px) {
    left: 3rem;
  }

  @media screen and (max-width: 991px) {
    left: 2rem;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledFadeLink = styled(FadeLink)`
  font-size: 2.2rem;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`;

const OnlineShops = ({
  location: { pathname: path },
  transitionStatus,
  entry,
  exit,
  data,
}) => {
  const headingImage = data.allFile.nodes.find(
    ({ name }) => name === 'obraz z symbolem sklepow internetowych',
  ).childImageSharp.fluid;

  const { t } = useLng();

  return (
    <Layout
      path={path}
      id="strony-internetowe"
      nonBgc
      transitionStatus={transitionStatus}
      entry={entry}
      exit={exit}
    >
      <SEO title={t("shopSeo")} />
      <Section nonGrid site first mb="0">
        <HeaderSection pl>
          <HeaderTextWrapper>
            <StyledSocialMedia />
            <div>
              <HeaderTitle small>
                {t("shop")}
              </HeaderTitle>
              <HeaderParagraph big>
                {t("shop1")}
              </HeaderParagraph>
              <HeaderParagraph>
                {t("shop2")}
              </HeaderParagraph>
            </div>
          </HeaderTextWrapper>
          <StyledImageWrapper
            image={headingImage}
            alt="obraz z symbolem sklepów internetowych"
          />
        </HeaderSection>
      </Section>
      <OnlineShopsPageContext.Provider value={{ data: data.allFile.nodes }}>
        <>
          <WhatYouGetSection />
          <PopularPlatformsSection />
          <OnlineShopIntegrationSection />
          <UIUXSection />
        </>
      </OnlineShopsPageContext.Provider>
      <MainSection>
        <Wrapper nonGrid>
          <FadeIn>
            <Label>
              {t("shop34")}
            </Label>
          </FadeIn>
          <FadeIn>
            <Paragraph>
              {t("shop35")}.
            </Paragraph>
          </FadeIn>
        </Wrapper>
        <Wrapper nonGrid>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <StyledFadeLink to="/projekty" stylish>
              {t("zobaczRealizacje")}
            </StyledFadeLink>
          </div>
        </Wrapper>
      </MainSection>
    </Layout>
  );
};

export const onlineShopsQuery = graphql`
  query OnlineShopsQuery {
    allFile(filter: { relativeDirectory: { eq: "online-shops-page" } }) {
      nodes {
        name
        childImageSharp {
          fluid(maxWidth: 1920, quality: 90) {
            ...GatsbyImageSharpFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default OnlineShops;
