import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import List from 'src/components/shared/List/List';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import { OnlineShopsPageContext } from 'src/pages/sklepy-internetowe';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import useLng from "src/hooks/useLng";

const StyledParagraphInfo = styled(ParagraphInfo)`
  font-size: ${({ small }) => (small ? '1.8rem' : '2rem')};
  line-height: 1.5;
  text-align: ${({ center }) => center && 'center'};

  @media screen and (max-width: 767px) {
    max-width: 100%;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  max-width: ${({ mw }) => mw && '80%'};
  margin: 0 0 5rem;

  @media screen and (max-width: 1199px) {
    padding: 0;
  }
`;

const StyledList = styled(List)`
  margin-bottom: 8rem;

  @media screen and (max-width: 991px) {
    margin-right: 2rem;
    margin-bottom: 5rem;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 6rem;

  @media screen and (max-width: 767px) {
    margin-bottom: 4rem;
  }
`;

const PopularPlatformsSection = () => {
  const { data } = useContext(OnlineShopsPageContext);
  const {t} = useLng();
  const platformySklepoweImg = data?.find(
    ({ name }) => name === 'platformy sklepowe',
  );

  return (
    <Section>
      <Wrapper nonGrid>
        <FadeIn>
          <Label>
            {t("shop13")}
          </Label>
        </FadeIn>
        <FadeIn>
          <StyledParagraphInfo>
            {t("shop14")}
          </StyledParagraphInfo>
        </FadeIn>
        <FadeIn>
          <StyledParagraphInfo>
            {t("shop15")}
          </StyledParagraphInfo>
        </FadeIn>
      </Wrapper>
      <StyledWrapper mw>
        <div>
          <InnerBox>
            <StyledInnerWrapper pl nonGrid>
              <StyledSubTitle>
                {t("shop16")}
              </StyledSubTitle>
              <StyledList
                items={[
                  'Wordpress + Woocommerce,',
                  'Presta,',
                  'Shoper,',
                  'Magento.',
                ]}
              />
            </StyledInnerWrapper>
          </InnerBox>
          <StyledInnerWrapper pl nonGrid mw>
            <FadeIn>
              <StyledParagraphInfo>
                {t("shop17")}
              </StyledParagraphInfo>
            </FadeIn>
            <FadeIn>
              <StyledParagraphInfo>
                {t("shop18")}
              </StyledParagraphInfo>
            </FadeIn>
          </StyledInnerWrapper>
        </div>
        <MoveIn>
          <ImageWrapper
            image={platformySklepoweImg?.childImageSharp.fluid ?? ""}
            alt="platformy sklepowe"
          />
        </MoveIn>
      </StyledWrapper>
    </Section>
  );
};

export default PopularPlatformsSection;
