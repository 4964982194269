import React, { useContext } from 'react';
import { BenefitDescriptionBox } from 'src/components/Sites/ServicesPages/shared/Benefits';
import ParagraphInfo from 'src/components/shared/text/ParagraphInfo';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import styled from 'styled-components';
import ImageWrapper from 'src/components/shared/ImageWrapper/ImageWrapper';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import { OnlineShopsPageContext } from 'src/pages/sklepy-internetowe';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import FadeLink from '../../../shared/navigations/FadeLink/FadeLink';
import useLng from "src/hooks/useLng";

const StyledMobileWrapper = styled.div`
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const StyledWrapper = styled(Wrapper)`
  display: flex;
  align-items: flex-start;

  @media screen and (max-width: 1199px) {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  @media screen and (max-width: 767px) {
    display: block;
    margin-top: 0;
  }
`;

const StyledDesktopWrapper = styled.div`
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const StyledImageWrapper = styled(ImageWrapper)`
  @media screen and (max-width: 767px) {
    margin: 7rem 0 0;
  }
`;

const StyledTitle = styled(SubTitle)`
  margin-bottom: 8rem;

  @media screen and (max-width: 991px) {
    margin-bottom: 4rem;
  }
`;

const StyledInnerBox = styled(InnerBox)`
  @media screen and (max-width: 1500px) {
    padding-right: 0;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  margin-bottom: 0;

  @media screen and (max-width: 1199px) {
    display: block;
    padding: 0;
  }
`;

const WhatYouGetSection = () => {
  const { data } = useContext(OnlineShopsPageContext);
const {t} = useLng();
  const sliderImages = data
    ?.filter(({ name }) => name === 'piata aleja' || name === 'oh charlie')
    .sort((a, b) => (b.name < a.name ? -1 : 1));
  const grayRectangle = data?.find(({ name }) => name === 'szary prostokat')
    .childImageSharp.fluid;

  return (
    <Section>
      <StyledWrapper mw>
        <StyledInnerWrapper mw>
          <MoveIn>
            <Slider onlyDesktop m="7rem auto" slides={sliderImages} />
          </MoveIn>
          <InnerBox>
            <StyledInnerWrapper pl>
              <BenefitDescriptionBox>
                <StyledMobileWrapper>
                  <FadeIn>
                    <StyledTitle>
                      {t("shop3")}
                    </StyledTitle>
                  </FadeIn>
                </StyledMobileWrapper>
                <FadeIn>
                  <ParagraphInfo smallMedium bold>
                    {t("shop4")}
                  </ParagraphInfo>
                </FadeIn>
                <FadeIn>
                  <ParagraphInfo smallMedium>
                    {t("shop5")}
                  </ParagraphInfo>
                </FadeIn>
              </BenefitDescriptionBox>
              <BenefitDescriptionBox>
                <FadeIn>
                  <ParagraphInfo smallMedium bold>
                    {t("shop6")}
                  </ParagraphInfo>
                </FadeIn>
                <FadeIn>
                  <ParagraphInfo smallMedium>
                    {t("shop7")}
                  </ParagraphInfo>
                </FadeIn>
              </BenefitDescriptionBox>
              <StyledMobileWrapper>
                <MoveIn>
                  <Slider m="7rem auto" slides={sliderImages} />
                </MoveIn>
              </StyledMobileWrapper>
            </StyledInnerWrapper>
          </InnerBox>
        </StyledInnerWrapper>
        <StyledInnerWrapper mw>
          <StyledInnerBox bigPadding>
            <StyledDesktopWrapper>
              <FadeIn>
                <StyledTitle>
                  {t("shop8")}
                </StyledTitle>
              </FadeIn>
            </StyledDesktopWrapper>
            <BenefitDescriptionBox>
              <FadeIn>
                <ParagraphInfo smallMedium bold>
                  {t("shop9")}
                </ParagraphInfo>
              </FadeIn>
              <FadeIn>
                <ParagraphInfo smallMedium>
                  {t("shop10")}
                </ParagraphInfo>
              </FadeIn>
            </BenefitDescriptionBox>
            <BenefitDescriptionBox>
              <FadeIn>
                <ParagraphInfo smallMedium bold>
                  {t("shop11")}
                </ParagraphInfo>
              </FadeIn>
              <FadeIn>
                <ParagraphInfo smallMedium>
                  {t("shop12")}
                </ParagraphInfo>
              </FadeIn>
            </BenefitDescriptionBox>
          </StyledInnerBox>
          <StyledInnerWrapper pr>
            <MoveIn>
              <StyledImageWrapper image={grayRectangle} alt="Szary prostokąt" />
            </MoveIn>
          </StyledInnerWrapper>
        </StyledInnerWrapper>
      </StyledWrapper>
    </Section>
  );
};

export default WhatYouGetSection;
