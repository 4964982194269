import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  MainSection as Section,
  Wrapper,
} from 'src/components/Sites/ServicesPages/shared/ServicesPages';
import Label from 'src/components/Sites/ServicesPages/shared/Label';
import Paragraph from 'src/components/Sites/ServicesPages/shared/Paragraph';
import SubTitle from 'src/components/Sites/ServicesPages/shared/SubTitle';
import InnerBox from 'src/components/Sites/ServicesPages/shared/InnerBox';
import Slider from 'src/components/Sites/ServicesPages/shared/Slider';
import ColoredBox from 'src/components/Sites/ServicesPages/shared/ColoredBox';
import { OnlineShopsPageContext } from 'src/pages/sklepy-internetowe';
import { FadeIn, MoveIn } from 'src/components/shared/Animations/Animations';
import FadeLink from '../../../shared/navigations/FadeLink/FadeLink';
import useLng from "src/hooks/useLng";
import {useI18next} from "gatsby-plugin-react-i18next";

const StyledWrapper = styled(Wrapper)`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;

  @media screen and (max-width: 767px) {
    display: block;
  }
`;

const StyledInnerWrapper = styled(Wrapper)`
  @media screen and (max-width: 1199px) {
    padding: 0;
    display: block;
  }
`;

const StyledSubTitle = styled(SubTitle)`
  margin-bottom: 3rem;
`;

const UIUXSection = () => {
  const { data } = useContext(OnlineShopsPageContext);
  const {t} = useLng();
  const {language} = useI18next();
  const sliderImages = data?.filter(
    ({ name }) => name === 'ux ui' || name === 'user experience',
  );
  return (
    <Section>
      <Wrapper nonGrid>
        <FadeIn>
          <Label>{t("shop22")}</Label>
        </FadeIn>
        <FadeIn>
          <Paragraph>
            {t("shop23")}
          </Paragraph>
        </FadeIn>
        <FadeIn>
          <Paragraph>
            {t("shop24")}
          </Paragraph>
        </FadeIn>
      </Wrapper>
      <StyledWrapper mw>
        <StyledInnerWrapper pl>
          <InnerBox>
            <FadeIn>
              <SubTitle>
                {t("shop25")}
              </SubTitle>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("shop26")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("shop27")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small bold>
                {t("shop28")}
              </Paragraph>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("shop29")}
              </Paragraph>
            </FadeIn>
          </InnerBox>
        </StyledInnerWrapper>
        <StyledInnerWrapper>
          <MoveIn>
            <Slider left slides={sliderImages} />
          </MoveIn>
        </StyledInnerWrapper>
      </StyledWrapper>
      <StyledWrapper mw>
        <StyledInnerWrapper pl>
          <InnerBox>
            <FadeIn>
              <StyledSubTitle>
                {t("shop30")}
              </StyledSubTitle>
            </FadeIn>
            <FadeIn>
              <Paragraph small>
                {t("shop31")}
              </Paragraph>
            </FadeIn>
          </InnerBox>
        </StyledInnerWrapper>
        <StyledInnerWrapper>
          <FadeIn right>
            <ColoredBox>
              <InnerBox mw>
                <FadeIn animationToProp={{ delay: 1 }}>
                  <StyledSubTitle>
                    {t("shop32")}
                  </StyledSubTitle>
                  <Paragraph small>
                    {t("shop33")}
                  </Paragraph>
                </FadeIn>
              </InnerBox>
            </ColoredBox>
          </FadeIn>
        </StyledInnerWrapper>
      </StyledWrapper>
    </Section>
  );
};

export default UIUXSection;
